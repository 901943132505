import {InlinePreviewImage} from 'components/customer/Materials/InlinePreviewImage';
import {useProductContext} from 'contexts';
import {cloneDeep} from 'lodash';
import React, {useCallback} from 'react';
import Slider from 'shared/slider/Slider';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';
import {OverlayTrigger} from 'shared/components/OverlayTrigger';

interface RoomAssistantTableProps {
    handleClick: () => void;
}

interface RoomAssistantContent {
    changed_data: {
        items: {
            name: string;
            apply_to_room: boolean;
            is_saved: boolean;
            room_data: string;
            product_data: string;
            image: string;
        }[];
        category: string;
    }[];
}

const RoomAssistantTable = ({handleClick}: RoomAssistantTableProps) => {
    const {
        roomAssitantContent,
        setRoomAssistantContent,
    }: {
        roomAssitantContent: RoomAssistantContent;
        setRoomAssistantContent: (data: RoomAssistantContent) => void;
    } = useProductContext();

    const handleChange = useCallback(
        (key: string, value: boolean) => {
            const data = cloneDeep(roomAssitantContent);
            data['changed_data'].map((contents) => {
                contents.items.map((content) => {
                    if (
                        content.hasOwnProperty('name') &&
                        content['name'] === key
                    ) {
                        content['apply_to_room'] = value;
                    }
                    if (
                        [
                            'Exterior Material Colour',
                            'Exterior Edge Colour',
                            'Door Style',
                        ].includes(key) &&
                        [
                            'Exterior Material Colour',
                            'Exterior Edge Colour',
                            'Door Style',
                        ].includes(content['name'])
                    ) {
                        content['apply_to_room'] = value;
                    }
                    if (
                        [
                            'Carcase Material Colour',
                            'Carcase Edge Colour',
                        ].includes(key) &&
                        [
                            'Carcase Material Colour',
                            'Carcase Edge Colour',
                        ].includes(content['name'])
                    ) {
                        content['apply_to_room'] = value;
                    }
                    if (
                        (key == 'Include Hardware' &&
                            content['name'] == 'Include Assembly' &&
                            !value) ||
                        (key == 'Include Assembly' &&
                            content['name'] == 'Include Hardware' &&
                            value)
                    ) {
                        content['apply_to_room'] = value;
                    }
                });
            });
            setRoomAssistantContent(data);
        },
        [roomAssitantContent, setRoomAssistantContent]
    );

    return (
        <>
            <TextContainer>
                Some of your selections are different from the current Product
                Defaults. Would you like to update your job defaults so any
                future products will use these new settings?
            </TextContainer>
            <TextContainerBottom>
                <p>
                    Don’t want to use Room Assistant? Click{' '}
                    <span
                        style={{color: 'blue', cursor: 'pointer'}}
                        onClick={handleClick}>
                        <u>
                            <strong>here</strong>
                        </u>
                    </span>{' '}
                    to turn off this feature
                </p>
            </TextContainerBottom>
            <BodyContainer>
                {roomAssitantContent['changed_data'].map((content) => {
                    if (content.items.every((item) => item.is_saved)) {
                        return null;
                    } else {
                        return (
                            <TableContainer key={content.category}>
                                <StyledTable>
                                    <thead>
                                        <tr>
                                            <StyledLeftTh colSpan={2}>
                                                {content.category}
                                            </StyledLeftTh>
                                            <StyledRightTh>
                                                Use as default setting
                                            </StyledRightTh>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {content.items.map((data) => {
                                            if (
                                                data &&
                                                data.hasOwnProperty('name') &&
                                                !data.is_saved
                                            ) {
                                                return (
                                                    <tr key={data.name}>
                                                        <StyledTD>
                                                            <NameContainer>
                                                                <NameTextContainer>
                                                                    {data.name}
                                                                </NameTextContainer>
                                                                <ImageContainer>
                                                                    <OverlayTrigger
                                                                        placement="right"
                                                                        overlay={`Previous default value was ${data.room_data}`}>
                                                                        <div>
                                                                            <Icon iconName="More-Info.svg" />
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </ImageContainer>
                                                            </NameContainer>
                                                        </StyledTD>
                                                        <StyledTD>
                                                            <FlexedDiv>
                                                                <InlinePreviewImage
                                                                    image={
                                                                        data.image
                                                                    }
                                                                    inlinePreviewImageBorder={
                                                                        false
                                                                    }
                                                                    showPopover={
                                                                        true
                                                                    }
                                                                    popoverWidth={
                                                                        75
                                                                    }
                                                                    height={
                                                                        data.name.includes(
                                                                            'Edge'
                                                                        )
                                                                            ? 20
                                                                            : 40
                                                                    }
                                                                    width={40}
                                                                />
                                                                <ContentContainer>
                                                                    {
                                                                        data.product_data
                                                                    }
                                                                </ContentContainer>
                                                            </FlexedDiv>
                                                        </StyledTD>
                                                        <StyledTD>
                                                            <Slider
                                                                value={
                                                                    data.apply_to_room
                                                                }
                                                                isLoading={
                                                                    false
                                                                }
                                                                name={data.name}
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </StyledTD>
                                                    </tr>
                                                );
                                            }
                                        })}
                                    </tbody>
                                </StyledTable>
                            </TableContainer>
                        );
                    }
                })}
            </BodyContainer>
        </>
    );
};

export default RoomAssistantTable;

const StyledTable = styled.table`
    width: 100%;
    tr > td:first-child {
        width: 26%;
    }
    tr > td:nth-child(2) {
        width: 50%;
    }
    tr > td:last-child {
        width: 19%;
    }
    tr:nth-child(odd) {
        background: rgb(var(--table_row_primary));
    }
    tr:nth-child(even) {
        background: rgb(var(--table_row_secondary));
    }
    tr:last-child > td:first-child {
        border-bottom-left-radius: 12px;
    }
    tr:last-child > td:last-child {
        border-bottom-right-radius: 12px;
    }
`;

const StyledLeftTh = styled.th`
    text-align: center;
    background: rgb(var(--secondary_colour));
    padding: 4px 0;
    border-top-left-radius: 12px;
    color: white;
    border: 1px solid white;
    border-bottom: 0;
`;

const StyledRightTh = styled.th`
    text-align: center;
    background: rgb(var(--primary_colour));
    padding: 5px 0;
    border-top-right-radius: 12px;
    color: white;
    border: 1px solid white;
    border-bottom: 0;
`;

const StyledTD = styled.td`
    padding: 4px 50px;
    border-bottom: 1px solid white;
    @media (max-width: 1120px) {
        padding: 4px 30px;
    }
    @media (max-width: 770px) {
        padding: 4px 8px;
    }
`;

const ContentContainer = styled.div`
    margin-left: 14px;
`;

const FlexedDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

const BodyContainer = styled.div`
    overflow-y: auto;
    min-height: 200px;
    max-height: 435px;
`;

const TableContainer = styled.div`
    width: 100%;
    margin-bottom: 18px;
`;

const TextContainer = styled.div`
    text-align: center;
    color: #000000;
    font-weight: 600;
    font-size: 17px;
    margin: 10px 26px 5px;
    @media (max-width: 700px) {
        font-size: 12px;
    }
`;

const TextContainerBottom = styled(TextContainer)`
    font-size: small !important;
    font-style: italic !important;
    font-weight: 400 !important;
`;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const ImageContainer = styled.div`
    min-width: 18px;
    max-width: 18px;
`;
const NameTextContainer = styled.div`
    width: 155px;
    @media (max-width: 1120px) {
        width: 75px;
    }
`;
