import React, {useCallback, useEffect, useMemo} from 'react';
import Select, {
    StylesConfig,
    Props as ReactSelectProps,
    components,
    DropdownIndicatorProps,
    ActionMeta,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import {useTheme} from 'styled-components';
import {Field, FieldProps, useField} from 'formik';
import Icon from 'shared/Icon';

export interface LabelOption {
    label: string;
    value: string;
    color?: string;
    suspended?: boolean;
    disabled?: boolean;
}

interface SelectFieldProps {
    options: LabelOption[];
    placeholderOption?: LabelOption;
    asyncMode?: boolean;
    searchIcon?: boolean;
    invalid?: boolean;
    width?: string;
    styleType?: 'filterStyle' | 'formStyle';
    customOnChange?: (name: string, value: string, options?: unknown[]) => void;
    defaultFirstValue?: boolean;
    isQFP?: boolean;
    updateToDefaultOnDisable?: boolean;
}

export const formatColoredLabel = (
    {label, color}: LabelOption,
    {context}: {context: string}
) =>
    context === 'menu' ? (
        <div style={{display: 'flex', width: '100%'}}>
            <div
                style={{
                    color: 'white',
                    background: color,
                    padding: '5px 8px',
                    borderRadius: '50px',
                }}>
                {label}
            </div>
        </div>
    ) : (
        label
    );

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon width="24px" iconName="search-grey.svg" />
        </components.DropdownIndicator>
    );
};

type FieldType = number | string | string[];
// keeping the custom components within the same file eg. DropdownIndicator
// eslint-disable-next-line react/no-multi-comp
const CustomSelect = ({
    options,
    isMulti = false,
    field,
    placeholderOption,
    asyncMode = false,
    searchIcon = false,
    invalid = false,
    width = '100%',
    styleType = 'formStyle',
    customOnChange,
    defaultFirstValue,
    isQFP,
    updateToDefaultOnDisable,
    ...otherProps
}: SelectFieldProps & FieldProps & ReactSelectProps) => {
    const [, , {setValue, setTouched}] = useField<FieldType>(field.name);
    const onChange = useCallback(
        (
            data: LabelOption | LabelOption[],
            actionMeta: ActionMeta<LabelOption>
        ) => {
            if (isMulti) {
                // when the selected value is disabled dont remove from the selected options
                switch (actionMeta.action) {
                    case 'remove-value':
                    case 'pop-value':
                        if (actionMeta.removedValue.disabled) {
                            return;
                        }
                        break;
                }
            }
            const values = isMulti
                ? (data as LabelOption[]).map(({value}) => value)
                : (data as LabelOption).value;
            if (customOnChange) {
                customOnChange(field.name, values, options);
            } else {
                const value =
                    typeof values === 'string' &&
                    values !== '' &&
                    !isNaN(values)
                        ? Number(values)
                        : values;
                void setValue(value);
            }
        },
        [customOnChange]
    );

    const onBlur = useCallback(() => {
        void setTouched(true);
    }, []);

    useEffect(() => {
        if (!!options?.length && defaultFirstValue) {
            const value = options.find((option) => option.value == field.value);
            if (!value) {
                void setValue(options[0].value);
            }
        }
    }, [options]);

    useEffect(() => {
        if (!!options?.length && updateToDefaultOnDisable) {
            const value = options.find((option) => option.value == field.value);
            if (!!value?.disabled) {
                onChange({value: options[0].value, label: options[0].label});
            }
        }
    }, [options, updateToDefaultOnDisable, onChange]);

    const theme = useTheme();
    const primaryColor = theme.colors.primary.main;
    const secondaryColor = theme.colors.secondary.main;

    const formStyle = useMemo(() => {
        const colourStyles: StylesConfig<LabelOption, true> = {
            container: (styles) => ({...styles, width}),
            control: (styles, {isDisabled}) => ({
                ...styles,
                backgroundColor: invalid ? '#f4cfc0' : 'white',
                borderColor: primaryColor,
                borderWidth: '2px',
                borderRadius: '8px',
                boxShadow: 'none',
                ':active': {
                    borderColor: primaryColor,
                },
                ':hover': {
                    borderColor: primaryColor,
                },
                ':focus': {
                    borderColor: primaryColor,
                },
                ...(isDisabled && !invalid ? {backgroundColor: '#e9ecef'} : {}),
                ...(isQFP ? {minHeight: '35px', height: '35x'} : {}),
            }),
            input: (styles) => {
                if (isQFP) {
                    return {
                        ...styles,
                        fontWeight: '500',
                        fontSize: '0.84rem',
                        color: '#6b6f70',
                        margin: 0,
                        input: {boxShadow: 'none !important'},
                    };
                }
                return {
                    ...styles,
                    fontSize: '1rem',
                    color: '#495057',
                    input: {boxShadow: 'none !important'},
                };
            },
            option: (styles, {isSelected}) => {
                const singleSelect = !isMulti
                    ? {
                          backgroundColor: isSelected
                              ? secondaryColor
                              : undefined,
                          ':hover': {
                              ...styles[':hover'],
                              backgroundColor: !isSelected
                                  ? primaryColor
                                  : undefined,
                              color: 'white',
                          },
                          ':active': {
                              ...styles[':active'],
                              backgroundColor: !isSelected
                                  ? primaryColor
                                  : undefined,
                              color: 'white',
                          },
                      }
                    : {};
                const optionStyle = {
                    ...styles,
                    ...singleSelect,
                    ...(isQFP ? {padding: '4px 12px'} : {}),
                    cursor: 'pointer',
                };
                return optionStyle;
            },
            multiValue: (styles, {data}) => ({
                ...styles,
                fontSize: '1rem',
                backgroundColor: data.color,
                borderRadius: '50px',
                padding: '2px',
            }),
            multiValueLabel: (styles) => ({
                ...styles,
                color: 'white',
            }),
            multiValueRemove: (styles, {data}) => ({
                ...styles,
                ...(data.disabled ? {display: 'none'} : {}),
                color: 'white',
                ':hover': {
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                },
            }),
            placeholder: (styles) => ({
                ...styles,
                fontSize: isQFP ? '0.84rem' : '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }),
            valueContainer: (styles) => ({
                ...styles,
                fontSize: '1rem',
                padding: '2px 10px',
            }),
            singleValue: (styles) => {
                if (isQFP) {
                    return {
                        ...styles,
                        fontWeight: '500',
                        fontSize: '0.84rem',
                        color: '#6b6f70',
                    };
                }
                return {
                    ...styles,
                    fontSize: '1rem',
                    color: '#495057',
                };
            },
            dropdownIndicator: (styles) => ({
                ...styles,
                ...(searchIcon ? {padding: '5px 6px'} : {}),
                ...(invalid ? {color: 'hsl(0, 0%, 70%)'} : {}),
                padding: isQFP ? '4px' : '7px',
            }),
            clearIndicator: (styles) => ({
                ...styles,
                padding: isQFP ? '4px' : '7px',
            }),
            menu: (styles) => ({
                ...styles,
                zIndex: 5,
                width: 'max-content',
                maxWidth: '200%',
                minWidth: '100%',
            }),
            menuList: (styles) => ({
                ...styles,
                fontSize: isQFP ? '0.84rem' : '1rem',
                '&.multi_formatted': {
                    fontSize: '0.85rem',
                },
            }),
        };
        return colourStyles;
    }, [primaryColor, invalid]);

    const filterStyle = useMemo(() => {
        const colourStyles: StylesConfig<LabelOption, true> = {
            container: (styles) => ({...styles, width}),
            control: (styles) => ({
                ...styles,
                backgroundColor: invalid ? '#f4cfc0' : 'white',
                borderColor: '#999 #bbb #ddd',
                borderWidth: '1px',
                borderRadius: '0',
                boxShadow: 'none',
                minHeight: '100%',
                ':active': {
                    borderColor: '#999 #bbb #ddd',
                },
                ':hover': {
                    borderColor: '#999 #bbb #ddd',
                },
                ':focus': {
                    borderColor: '#999 #bbb #ddd',
                },
            }),
            input: (styles) => ({
                ...styles,
                input: {boxShadow: 'none !important'},
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                padding: searchIcon ? '0' : '4px',
            }),
            option: (styles, {data, isSelected}) => {
                const singleSelect = !isMulti
                    ? {
                          backgroundColor: isSelected
                              ? '#2684FF'
                              : data?.suspended
                              ? '#A4B4BA'
                              : styles.backgroundColor,
                          ':active': {
                              ...styles[':active'],
                          },
                          ':hover': {
                              ...styles[':active'],
                          },
                      }
                    : {};
                const optionStyle = {
                    ...styles,
                    ...singleSelect,
                    cursor: 'pointer',
                };
                return optionStyle;
            },
        };
        return colourStyles;
    }, [invalid]);

    const styleMap: {[key: string]: StylesConfig<LabelOption, true>} = {
        formStyle,
        filterStyle,
    };

    const disableNoOption = useCallback(() => null, []);
    const selectOptions = useMemo(() => {
        if (placeholderOption) {
            return [placeholderOption, ...options];
        }
        return options;
    }, [options]);
    const getValue = () => {
        return isMulti
            ? options?.filter((option) =>
                  (field.value as string[])?.includes(option.value)
              )
            : options?.find((option) => {
                  return String(option.value) === String(field.value);
              });
    };

    const isOptionDisabled = useCallback(
        (option: LabelOption) => !!option?.disabled,
        []
    );

    return asyncMode ? (
        <AsyncSelect
            isMulti={isMulti}
            styles={styleMap[String(styleType)]}
            noOptionsMessage={disableNoOption}
            onBlur={onBlur}
            cacheOptions
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            {...otherProps}
        />
    ) : (
        <Select
            isMulti={isMulti}
            options={selectOptions}
            styles={styleMap[String(styleType)]}
            noOptionsMessage={disableNoOption}
            onChange={onChange}
            onBlur={onBlur}
            value={getValue()}
            isOptionDisabled={isOptionDisabled}
            {...otherProps}
        />
    );
};

// This is the wrapper component to simplify usage of the main component
// eslint-disable-next-line react/no-multi-comp
const SelectField = (props: SelectFieldProps & ReactSelectProps) => (
    <Field component={CustomSelect} {...props} />
);

export default SelectField;
