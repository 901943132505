import React from 'react';
import {Container} from 'react-bootstrap';
import {CartToggleButton} from 'shared/components/Header/CartToggleButton';
import {DashboardButton} from 'shared/components/Header/DashboardButton';
import {SearchBar} from 'shared';
import {useJobContext} from 'contexts';
import {CBCImage} from 'shared/components/Image';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useGetConfigurationQuery} from 'store/customer/appSlice';
import {User} from 'components/customer/Auth/store/userSlice';
import styled from 'styled-components';
import {MenuRenderer} from 'shared/components/HeaderMenu';

const HomeLink = styled.a`
    font-size: 12px;
    font-weight: bold;
    padding: 0 20px;
`;

export const Header = ({
    visible,
    userProfile,
}: {
    visible: boolean;
    userProfile: User;
}) => {
    const isSmallDevice = isDeviceSmall();
    const jobContext = useJobContext() as {expiredJobPrice?: boolean};
    const {data: configuration, isLoading: isConfigurationLoading} =
        useGetConfigurationQuery();

    return (
        <>
            {!isConfigurationLoading && configuration.environmentName ? (
                <div className="top_color_strip" />
            ) : null}

            <header>
                <section
                    style={{
                        marginTop: visible ? '0' : '-70px',
                        transition: 'margin-top 200ms',
                        zIndex: jobContext?.expiredJobPrice ? '1100' : 'auto', // show topbar only when job prices are expired under a modal
                    }}
                    className="header-contents">
                    <div>
                        <DashboardButton />
                    </div>
                    <div className="main-header">
                        <Container>
                            <div className="header-grid">
                                <div className="logo">
                                    <a href="/v2">
                                        <CBCImage
                                            id="manu_logo"
                                            src={
                                                !userProfile?.manufacturerLogo
                                                    ? 'logo.svg'
                                                    : userProfile?.manufacturerLogo
                                            }
                                            alt={userProfile.manufacturerName}
                                            title={userProfile.manufacturerName}
                                            data-cy="header-logo"
                                            localDirectory={
                                                !userProfile.manufacturerLogo
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </a>
                                    {!isSmallDevice ? (
                                        <HomeLink
                                            data-cy="header-home-link"
                                            href="/v2">
                                            Homepage
                                        </HomeLink>
                                    ) : null}
                                </div>

                                {/* NOTE:: Remove after beta is done */}
                                {isSmallDevice ? null : (
                                    <div className="notification-bell-container" />
                                )}
                                {/* NOTE:: Remove after beta is done */}
                                {!isConfigurationLoading &&
                                configuration.environmentName ? (
                                    <div className="info">
                                        YOU&apos;RE IN{' '}
                                        {configuration.environmentName.toUpperCase()}
                                    </div>
                                ) : null}
                                <div className="main-menu">
                                    <MenuRenderer />
                                </div>
                                <div className="searchContainer">
                                    <SearchBar />
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div>
                        <CartToggleButton />
                    </div>
                </section>
            </header>
        </>
    );
};
