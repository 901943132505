import React from 'react';
import {Image} from 'react-bootstrap';
import {Icon} from 'shared/helpers';

interface CBCImageProp {
    src: string;
    alt?: string;
    color?: string;
    id?: string;
    title?: string;
    className?: string;
    style?: React.CSSProperties;
    localDirectory?: boolean;
}

// Assumes that all the icons are inside assets/images/icons directory
export const CBCImage = ({src, ...props}: CBCImageProp) => {
    if (typeof src !== 'undefined' && src !== '') {
        const imageNameParts: Array<string> = src.split('.');
        if (imageNameParts.pop() === 'svg') {
            return <Icon {...props} iconName={src} />;
        } else {
            return <Image {...props} src={src} />;
        }
    } else {
        return null;
    }
};
