import {useAppContext, useProductContext} from 'contexts';
import {useFormikContext} from 'formik';
import React, {useCallback, useEffect, useRef} from 'react';
import {InputGroup} from 'react-bootstrap';
import {FormControl} from 'shared';
import {Icon} from 'shared/helpers';
import {DoorHinge} from 'shared/types';
import excel from 'shared/Excel';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';

type SizeType = {
    name: string;
    value: string | number;
    metric: string;
    hideDialog: () => void;
    inSpecsTab?: boolean;
    isQFP?: boolean;
    withDeleteIcon?: boolean;
    deleteAction?: () => void;
    customWidth?: string;
    selectHandler?: (name: string, value: number) => void;
    disabled?: boolean;
    isInvalid?: boolean;
    fieldSetIndex?: number;
};

export const Size = ({
    name,
    value,
    metric,
    hideDialog,
    isQFP,
    inSpecsTab,
    withDeleteIcon,
    deleteAction,
    customWidth,
    ...otherParams
}: SizeType): JSX.Element => {
    const {getMaterialOptions}: {getMaterialOptions: () => object} =
        useProductContext();
    const {userProfile} = useAppContext();
    const formik = useFormikContext<{[key: string]: number}>();
    const [searchParams] = useSearchParams();

    const updateFields = useRef<string[]>([
        'rem_door_shelf_height',
        'cabinet_height',
    ]).current;

    const cabinetId = searchParams.get('cabinetId');

    const updateHingeHolesAmount = useCallback(
        (height: number) => {
            if (formik) {
                let hingeReference: DoorHinge[] = [
                    {
                        amount: 3,
                        height: 900,
                    },
                    {
                        amount: 4,
                        height: 1500,
                    },
                    {
                        amount: 5,
                        height: 2200,
                    },
                ];
                let newHingeAmount = 2;

                if (
                    userProfile.doorHingeHoles &&
                    typeof userProfile.doorHingeHoles == 'object' &&
                    Object.keys(userProfile.doorHingeHoles).length > 0
                ) {
                    const amounts = Object.keys(userProfile.doorHingeHoles);

                    hingeReference = amounts.map(
                        (amount) =>
                            ({
                                amount: Number(amount),
                                height: userProfile.doorHingeHoles[
                                    Number(amount)
                                ],
                            } as DoorHinge)
                    );
                }

                hingeReference.forEach((hingeRef) => {
                    if (height >= hingeRef.height) {
                        newHingeAmount = hingeRef.amount;
                    }
                });

                formik.setFieldValue('door_hinge_amount', newHingeAmount);
                formik.setFieldValue('door_hinge_amount_1', newHingeAmount);
            }
        },
        [userProfile]
    );

    useEffect(() => {
        if (
            (typeof cabinetId === 'undefined' || cabinetId == null) &&
            updateFields.includes(name) &&
            formik &&
            formik.values.hasOwnProperty('door_hinge_amount')
        ) {
            if (
                name === 'cabinet_height' &&
                formik.values.hasOwnProperty('rem_door_shelf_height')
            ) {
                return;
            }

            const height = excel.calculate(formik.values[name], {
                ...formik.values,
                ...getMaterialOptions(),
            });

            updateHingeHolesAmount(Number(height));
        }
        return () => {
            hideDialog && hideDialog();
        };
    }, [value, cabinetId]);

    return (
        <InputGroup
            className="cbc-size"
            style={{
                width: !!customWidth
                    ? customWidth
                    : inSpecsTab && !isQFP
                    ? '150px'
                    : 'auto',
            }}>
            <FormControl
                {...otherParams}
                type="number"
                name={name}
                value={value}
                isQFP={isQFP}
            />
            <InputGroupText $isQFP={isQFP}>{metric}</InputGroupText>
            {withDeleteIcon ? (
                <Icon
                    iconName="Options-Delete.svg"
                    style={{width: 22, cursor: 'pointer', margin: '0 8px'}}
                    onClick={deleteAction}
                />
            ) : null}
        </InputGroup>
    );
};

export const InputGroupText = styled(InputGroup.Text)<{$isQFP: boolean}>`
    background: transparent;
    border: 0;
    padding: 0 8px 2px;
    font-size: ${({$isQFP}) => ($isQFP ? 1.2 : 0.85)}em;
    font-weight: 500;
    color: #6b6f70;
`;
